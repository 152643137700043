
import { computed, defineComponent, ref } from "vue";
import { NCheckbox, NCheckboxGroup } from "naive-ui";
import { MaterialModel, GroupModel } from "@/api/apiModel";
import { uploadModel, getGroupList } from "@/api/group";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "TextPane",
  props: {
    textListProp: {
      type: Array,
      require: true,
    },
  },
  components: { NCheckbox, NCheckboxGroup },
  setup(props, context) {
    const textList = computed(() => {
      return (
        props.textListProp?.filter((x: any) => x.mouldType == "话术") || []
      );
    });

    // 编辑
    const editText = (value: MaterialModel) => {
      context.emit("editText", value);
    };
    // 删除
    const deleteText = () => {
      context.emit("deleteText", checkList);
      checkList.value = [];
    };

    // 多选操作
    let isShowGray = ref(false);
    let isChooseAll = ref(false);
    let checkList = ref<Array<any>>([]);
    const chooseAll = (value: boolean) => {
      if (value) {
        isChooseAll.value = true;
        if (textList.value != undefined) {
          checkList.value = [];
          for (let i of textList.value) {
            checkList.value.push(i as any);
          }
          checkList.value = checkList.value.map((x: any) => x.id);
        }
      } else {
        isChooseAll.value = false;
        checkList.value = [];
      }
    };
    const handleUpdateValue = (value: Array<string>) => {
      checkList.value = value;
      isShowGray.value = true;
    };
    const getCount = () => {
      if (checkList.value == undefined) {
        return 0;
      }
      return checkList.value?.length;
    };

    const convertTolong = (value: string) => {
      if (value.length > 80) {
        return value.slice(0, 80) + "...";
      } else {
        return value;
      }
    };

    // 移动
    let isShowMove = ref(false);
    let moveSelect = ref<GroupModel>({
      id: "",
      mouldGroupName: "",
      mouldGroupType: "图片",
      num: 0,
    });
    let groupList = ref<Array<GroupModel>>();
    // 获取分组列表
    const showMoveDialog = async () => {
      isShowMove.value = true;
      const res = await getGroupList();
      if (res.code == 200) {
        groupList.value = res.data?.filter(
          (x: GroupModel) => x.mouldGroupType == "话术"
        );
      }
    };
    // 选中
    const chooseMove = (item: GroupModel) => {
      moveSelect.value = item;
    };
    // 移动
    const move = async () => {
      try {
        for (let id of checkList.value) {
          let mouldList = textList.value.filter(
            (x: any) => x.id == id
          ) as Array<MaterialModel>;
          if (mouldList[0] != undefined) {
            mouldList[0].mouldGroupId = moveSelect.value.id;
            mouldList[0].mouldGroupName = moveSelect.value.mouldGroupName;
          }
          await uploadModel(mouldList[0]);
        }
        ElMessage.success("移动成功");
        cancelMove();
        checkList.value = [];
        context.emit("refreshList");
      } catch (e) {
        ElMessage.error("移动失败" + e);
      }
    };
    // 取消
    const cancelMove = () => {
      moveSelect.value = {
        id: "",
        mouldGroupName: "",
        mouldGroupType: "话术",
        num: 0,
      };
      isShowMove.value = false;
    };

    return {
      textList,
      isChooseAll,
      editText,
      chooseAll,
      handleUpdateValue,
      getCount,
      deleteText,
      convertTolong,
      isShowGray,
      checkList,
      cancelMove,
      move,
      showMoveDialog,
      chooseMove,
      isShowMove,
      moveSelect,
      groupList,
    };
  },
});
