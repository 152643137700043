
import { computed, defineComponent, ref } from "vue";
import { NCheckbox, NCheckboxGroup } from "naive-ui";
import { ElMessage } from "element-plus";
import { MaterialModel, GroupModel } from "@/api/apiModel";
import { uploadModel, getGroupList } from "@/api/group";

export default defineComponent({
  name: "picPane",
  props: {
    picListProp: {
      type: Array,
      require: true,
    },
  },
  components: { NCheckbox, NCheckboxGroup },
  setup(props, context) {
    const picList = computed(() => {
      return props.picListProp?.filter((x: any) => x.mouldType == "图片") || [];
    });

    let isChooseAll = ref(false);
    let checkList = ref<Array<any>>([]);

    const chooseAll = (value: boolean) => {
      if (value) {
        isChooseAll.value = true;
        if (picList.value != undefined) {
          checkList.value = [];
          let test = picList.value;
          for (let i of test) {
            checkList.value.push(i);
          }
          checkList.value = checkList.value.map((x) => x.id);
        }
      } else {
        isChooseAll.value = false;
        checkList.value = [];
      }
    };
    const handleUpdateValue = (value: Array<string>) => {
      checkList.value = value;
    };

    const getCount = () => {
      if (checkList.value == undefined) {
        return 0;
      }
      return checkList.value?.length;
    };

    const deletePic = async () => {
      if (checkList.value == undefined) {
        ElMessage.error("请选择删除的图片");
        return;
      } else {
        context.emit("deletePic", checkList);
        checkList.value = [];
      }
    };
    // 编辑名称
    const editName = async (item: MaterialModel) => {
      if (item.mouldTitle !== "") {
        const res = await uploadModel(item);
        if (res.code == 200) {
          ElMessage.success("修改成功");
        }
      } else {
        ElMessage.warning("请输入名称");
      }
      // context.emit("refreshPic");
    };

    // 移动
    let isShowMove = ref(false);
    let moveSelect = ref<GroupModel>({
      id: "",
      mouldGroupName: "",
      mouldGroupType: "图片",
      num: 0,
    });
    let groupList = ref<Array<GroupModel>>();
    // 获取分组列表
    const showMoveDialog = async () => {
      isShowMove.value = true;
      const res = await getGroupList();
      if (res.code == 200) {
        groupList.value = res.data?.filter(
          (x: GroupModel) => x.mouldGroupType == "图片"
        );
      }
    };
    // 选中
    const chooseMove = (item: GroupModel) => {
      moveSelect.value = item;
    };
    // 移动
    const move = async () => {
      if (checkList.value.length == 0) {
        ElMessage.warning("请选择素材");
        return;
      }
      try {
        for (let id of checkList.value) {
          let mouldList = picList.value.filter(
            (x: any) => x.id == id
          ) as Array<MaterialModel>;
          if (mouldList[0] != undefined) {
            mouldList[0].mouldGroupId = moveSelect.value.id;
            mouldList[0].mouldGroupName = moveSelect.value.mouldGroupName;
          }
          await uploadModel(mouldList[0]);
        }
        ElMessage.success("移动成功");
        cancelMove();
        checkList.value = [];
        context.emit("refreshPic");
      } catch (e) {
        ElMessage.error("移动失败" + e);
      }
    };
    // 取消
    const cancelMove = () => {
      moveSelect.value = {
        id: "",
        mouldGroupName: "",
        mouldGroupType: "图片",
        num: 0,
      };
      isShowMove.value = false;
    };

    return {
      isChooseAll,
      checkList,
      picList,
      handleUpdateValue,
      chooseAll,
      getCount,
      deletePic,
      editName,
      showMoveDialog,
      isShowMove,
      groupList,
      moveSelect,
      chooseMove,
      move,
      cancelMove,
    };
  },
});
