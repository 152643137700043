
import {
  defineComponent,
  ref,
  onMounted,
  getCurrentInstance,
  computed,
} from "vue";
import { NTabs, NTabPane } from "naive-ui";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import {
  getGroupList,
  getModelByGroupId,
  uploadPic,
  addNewModel,
  uploadModel,
  deleteModule,
} from "@/api/group";
import { ElMessage } from "element-plus";
import { GroupModel, MaterialModel } from "@/api/apiModel";
import { deepClone } from "@/util/oauth";

import GroupList from "./group-list.vue";
import PicPane from "./pic-pane.vue";
import TextPane from "./text-pane.vue";
import MixPane from "./mix-pane.vue";
import ColorPane from "./color-pane.vue";

export default defineComponent({
  name: "material",
  components: {
    NTabs,
    NTabPane,
    GroupList,
    PicPane,
    TextPane,
    MixPane,
    ColorPane,
  },
  setup() {
    let search = ref("");
    // 获取分组
    let groupList = ref();
    const getGroup = async () => {
      const res = await getGroupList();
      if (res.code == 200) {
        groupList.value = [];

        for (let item of res.data) {
          item.isEdit = false;
          groupList.value.push({
            id: item.id,
            mouldGroupName: item.mouldGroupName,
            mouldGroupType: item.mouldGroupType,
            isEdit: false,
            num: item.num,
          });
        }
      } else {
        ElMessage.error("获取分组失败");
      }
    };

    let materialList = ref([]);
    let currentGroup = ref<GroupModel>({
      id: "",
      mouldGroupName: "",
      mouldGroupType: "", // "图片" | "话术" | "图文" | "颜色";
      num: 0,
    });
    // 获取当前分组下的模板
    const searchList = () => {
      getGroupItem(currentGroup.value);
    };
    const getGroupItem = async (item: GroupModel) => {
      currentGroup.value = item;
      const res = await getModelByGroupId(item.id, search.value);
      if (res.code == 200) {
        materialList.value = res.data;
      } else {
        ElMessage.error("获取素材失败");
      }
    };

    // 上传图片
    let uploadLoading = ref(false);
    let isShowUpload = ref(false);
    const uploadDialog = () => {
      if (currentGroup.value.id == "") {
        ElMessage.error("请选择分组");
        return;
      }
      isShowUpload.value = true;
    };
    const { proxy } = getCurrentInstance() as any;
    const uploadFile = async () => {
      const file = proxy.$refs.upload.uploadFiles;
      if (file.length == 0) {
        ElMessage.warning("请选择图片");
        return;
      }
      for (let pic of file) {
        uploadLoading.value = true;
        let form = new FormData();
        form.append("file", pic.raw);
        const res = await uploadPic(form);
        uploadLoading.value = false;
        const model: MaterialModel = {
          mouldTitle: pic.raw.name,
          mould: "",
          id: "",
          mouldImgName: [res],
          mouldType: "图片",
          mouldGroupId: currentGroup.value.id,
          mouldGroupName: currentGroup.value.mouldGroupName,
        };

        try {
          uploadLoading.value = true;
          const addRes = await addNewModel(model);
          uploadLoading.value = false;
          if (addRes.code == 200) {
            //
          } else {
            ElMessage.error("上传失败" + addRes.msg);
          }
        } catch (e) {
          ElMessage.error("上传失败" + e);
        }
      }
      beforeClose();
      await getGroup();
      ElMessage.success("上传成功");
      getGroupItem(currentGroup.value);
    };
    // 删除素材（模板）
    const deleteList = async (ModelList: any) => {
      const res = await deleteModule(ModelList.value);
      if (res.code == 200) {
        ElMessage.success("删除成功");
      } else {
        ElMessage.error("删除失败" + res.msg);
      }
      getGroupItem(currentGroup.value);
    };
    const beforeClose = () => {
      isShowUpload.value = false;
      proxy.$refs.upload.uploadFiles = [];
    };

    // 新增话术
    let dialogTitle = ref("新增话术");
    let isShowDialog = ref(false);
    let form = ref<MaterialModel>({
      id: "",
      mouldTitle: "",
      mould: "",
      mouldImgName: [],
      mouldType: "话术",
      mouldGroupId: "",
      mouldGroupName: "",
    });
    const rules = ref({
      mouldTitle: [{ required: true, message: "请输入标题", trigger: "blur" }],
      mould: [{ required: true, message: "请输入内容", trigger: "blur" }],
    });
    // 编辑
    const editTextDialog = (value: MaterialModel) => {
      dialogTitle.value = "编辑话术";
      isShowDialog.value = true;
      form.value = deepClone(value);
      if (proxy.$refs.formName != undefined) {
        proxy.$refs.formName.resetFields();
      }
    };
    // 新增
    const addText = () => {
      if (currentGroup.value.id == "") {
        ElMessage.error("请选择分组");
        return;
      }
      dialogTitle.value = "新增话术";
      isShowDialog.value = true;
      form.value = {
        id: "",
        mouldTitle: "",
        mould: "",
        mouldImgName: [],
        mouldType: "话术",
        mouldGroupId: "",
        mouldGroupName: "",
      };
      if (proxy.$refs.formName != undefined) {
        proxy.$refs.formName.resetFields();
      }
    };
    // 取消
    const cacelTextDialog = () => {
      form.value = {
        id: "",
        mouldTitle: "",
        mould: "",
        mouldImgName: [],
        mouldType: "话术",
        mouldGroupId: "",
        mouldGroupName: "",
      };
      isShowDialog.value = false;
    };
    // 编辑
    const operatText = async (isAdd: boolean): Promise<void> => {
      form.value.mouldGroupId = currentGroup.value.id;
      form.value.mouldGroupName = currentGroup.value.mouldGroupName;
      form.value.mouldImgName = [""];
      let res = undefined;
      if (isAdd) {
        res = await addNewModel(form.value);
      } else {
        res = await uploadModel(form.value);
      }
      if (res.code == 200) {
        ElMessage.success("操作成功");
        isShowDialog.value = false;
        getGroupItem(currentGroup.value);
      } else {
        ElMessage.error("操作失败" + res.msg);
      }
    };

    // 新增图文
    const url = useRouter();
    const addMix = () => {
      if (currentGroup.value.id == "") {
        ElMessage.error("请选择分组");
        return;
      }
      const { href } = url.resolve({
        path: `/operateEditor`,
        query: {
          gid: currentGroup.value.id,
          gname: currentGroup.value.mouldGroupName,
          id: "-1",
          type: "mix",
        },
      });
      window.open(href, "_blank");
    };
    // 编辑图文
    const editMix = (value: MaterialModel) => {
      const { href } = url.resolve({
        path: `/operateEditor`,
        query: {
          gid: currentGroup.value.id,
          gname: currentGroup.value.mouldGroupName,
          id: value.id,
          type: "mix",
        },
      });
      window.open(href, "_blank");
    };

    const route = useRoute();
    const routeQuery = ref(route.query as any);
    const tabDefault = ref("img");
    onMounted(async () => {
      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState == "visible") {
          getGroup();
          if (routeQuery.value.type != undefined) {
            tabDefault.value = routeQuery.value.type;
          }
        }
      });
      await getGroup();
      if (routeQuery.value.type != undefined) {
        tabDefault.value = routeQuery.value.type;
      }
    });

    // 颜色
    let isShowColorDialog = ref(false);
    let colorDialogTitle = ref("");
    let backgroundType = ref("pure");
    let pureColor = ref("");
    let colorList = ref("");
    let colorName = ref("");
    let colorId = ref("");
    let direction = ref("to bottom");
    let directionList = [
      { label: "上下渐变", value: "to bottom" },
      { label: "左右渐变", value: "to right" },
      { label: "左上右下渐变", value: "to bottom right" },
      { label: "右上左下渐变", value: "to bottom left" },
    ];

    const tempCss = computed(() => {
      let style = {};
      if (backgroundType.value == "pure") {
        style["background-color"] = colorList.value;
      } else {
        style[
          "background-image"
        ] = `linear-gradient(${direction.value},${colorList.value})`;
      }
      return style;
    });

    const addColorDialog = () => {
      backgroundType.value = "pure";
      pureColor.value = "";
      colorList.value = "";
      colorName.value = "";
      colorId.value = "";
      direction.value = "to bottom";

      isShowColorDialog.value = true;
      colorDialogTitle.value = "新建背景颜色";
    };
    const colorChnage = (value: string) => {
      if (backgroundType.value == "pure") {
        colorList.value = value;
      } else {
        if (colorList.value == "") {
          colorList.value = value;
        } else {
          colorList.value += "," + value;
        }
      }
    };

    // 编辑颜色弹窗
    const editColorDialog = (item: any) => {
      isShowColorDialog.value = true;
      colorDialogTitle.value = "编辑背景颜色";
      colorName.value = item.colorName;
      backgroundType.value = item.backgroundType;
      colorList.value = item.chooseColor;
      colorId.value = item.id;
      direction.value = item.direction;
    };

    const operateColor = async (isAdd: boolean) => {
      let colorParam = {
        backgroundType: backgroundType.value,
        pureColor: pureColor.value,
        colorList: colorList.value,
        colorName: colorName.value,
        direction: direction.value,
      };
      const param = {
        id: colorId.value,
        mouldTitle: colorName.value,
        mould: JSON.stringify(colorParam),
        mouldImgName: [],
        mouldType: "颜色",
        mouldGroupId: currentGroup.value.id,
        mouldGroupName: currentGroup.value.mouldGroupName,
      };
      let res = undefined;
      if (isAdd) {
        res = await addNewModel(param);
      } else {
        res = await uploadModel(param);
      }

      if (res.code == 200) {
        ElMessage.success("操作成功");
        isShowColorDialog.value = false;
        getGroupItem(currentGroup.value);
      } else {
        ElMessage.error("操作失败" + res.msg);
      }
    };

    return {
      search,
      operatText,
      groupList,
      getGroup,
      getGroupItem,
      materialList,
      uploadDialog,
      isShowUpload,
      uploadFile,
      beforeClose,
      addText,
      isShowDialog,
      dialogTitle,
      editTextDialog,
      rules,
      cacelTextDialog,
      form,
      deleteList,
      addMix,
      editMix,
      addColorDialog,
      tabDefault,
      currentGroup,
      searchList,
      uploadLoading,
      backgroundType,
      pureColor,
      colorList,
      colorName,
      colorChnage,
      isShowColorDialog,
      colorDialogTitle,
      operateColor,
      editColorDialog,
      direction,
      directionList,
      tempCss,
    };
  },
});
