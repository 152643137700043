
import { defineComponent, ref, computed } from "vue";
import { NCheckbox, NCheckboxGroup } from "naive-ui";
import { ElMessage } from "element-plus";
export default defineComponent({
  props: {
    textListProp: {
      type: Array,
      require: true,
    },
  },
  components: { NCheckbox, NCheckboxGroup },
  setup(props, context) {
    const colorList = computed(() => {
      return (
        props.textListProp?.filter((x: any) => x.mouldType == "颜色") || []
      );
    });

    let checkList = ref<Array<any>>([]);
    let isChooseAll = ref(false);
    const chooseAll = (value: boolean) => {
      if (value) {
        isChooseAll.value = true;
        if (colorList.value != undefined) {
          checkList.value = [];
          let test = colorList.value;
          for (let i of test) {
            checkList.value.push(i);
          }
          checkList.value = checkList.value.map((x) => x.id);
        }
      } else {
        isChooseAll.value = false;
        checkList.value = [];
      }
    };
    let isShowGray = ref(false);
    const handleUpdateValue = (value: any) => {
      checkList.value = value;
      isShowGray.value = true;
    };

    const convertCss = (cssStr: string): any => {
      let cssObj = JSON.parse(cssStr);
      let style = {
        width: "200px",
        height: "100px",
      };
      if (cssObj.backgroundType == "pure") {
        style["background-color"] = cssObj.colorList;
      } else {
        style[
          "background-image"
        ] = `linear-gradient(to right,${cssObj.colorList})`;
      }
      return style;
    };

    const getCount = () => {
      if (checkList.value == undefined) {
        return 0;
      }
      return checkList.value?.length;
    };

    const editColorDialog = (item: any) => {
      let cssObj = JSON.parse(item.mould);
      let param = {
        id: item.id,
        colorName: item.mouldTitle,
        backgroundType: cssObj.backgroundType,
        chooseColor: cssObj.colorList,
        direction: cssObj.direction || "to bottom",
      };
      context.emit("editColor", param);
    };

    const deleteColor = () => {
      if (checkList.value == undefined) {
        ElMessage.error("请选择删除的背景配置");
        return;
      } else {
        context.emit("deleteColor", checkList);
        checkList.value = [];
      }
    };

    return {
      isChooseAll,
      chooseAll,
      colorList,
      checkList,
      isShowGray,
      handleUpdateValue,
      convertCss,
      getCount,
      editColorDialog,
      deleteColor,
    };
  },
});
