
import { defineComponent, ref, computed } from "vue";
import { GroupModel } from "@/api/apiModel";
import { updateGroup, addGroup, deleteGroup } from "@/api/group";
import { ElMessage } from "element-plus";
// import Draggable from "vue3-draggable";

export default defineComponent({
  name: "groupList",
  props: {
    groupListProp: {
      type: Array,
      required: true,
    },
    groupType: {
      type: String,
      required: true,
    },
    currentGroupProp: {
      type: Object,
      required: true,
    },
  },
  components: {
    // Draggable,
  },
  setup(props, context) {
    let currentGroup = computed(() => {
      return props.currentGroupProp || undefined;
    });

    let type = computed(() => props.groupType);
    let groupList = computed(() => {
      if (props.groupListProp != undefined) {
        let group = props.groupListProp.filter(
          (x: any) => x.mouldGroupType == props.groupType
        );
        if (
          currentGroup.value.id != "" &&
          currentGroup.value.mouldGroupType == type.value
        ) {
          changeGroupItem(currentGroup.value as GroupModel);
        } else {
          changeGroupItem(group[0] as GroupModel); // 默认点击
        }
        return props.groupListProp.filter(
          (x: any) => x.mouldGroupType == props.groupType
        );
      } else {
        return [];
      }
    });

    let selectedId = ref("");
    const changeGroupItem = (item: GroupModel) => {
      if (item != undefined) {
        selectedId.value = item.id || "";
        context.emit("chooseGroup", item);
      }
    };

    let isShowPop = ref(false);
    const closePopover = () => {
      newGroupName.value = "";
      isShowPop.value = false;
    };

    let newGroupName = ref("");
    const addGroupApi = async () => {
      let param: GroupModel = {
        id: "",
        mouldGroupName: newGroupName.value,
        mouldGroupType: type.value,
        num: 0,
      };
      const res = await addGroup(param);
      if (res.code == 200) {
        ElMessage.success("新增分组成功");
      } else {
        ElMessage.error(`新增分组失败：${res.msg}`);
      }
      closePopover();
      context.emit("refreshList");
    };

    let dragKey = ref(1);
    const updateGroupApi = async (item: GroupModel) => {
      const res = await updateGroup(item);
      dragKey.value += 1;
      if (res.code == 200) {
        ElMessage.success("修改成功");
      } else {
        ElMessage.error(`修改失败${res.msg}`);
      }
      context.emit("refreshList");
    };

    const deleteGroupName = async (item: GroupModel) => {
      const res = await deleteGroup(item.id);
      dragKey.value += 1;
      if (res.code == 200) {
        ElMessage.success("删除成功");
        // isShowDialog.value = false;
      } else {
        ElMessage.error(`删除失败${res.msg}`);
      }
      context.emit("refreshList");
    };

    let isShowDialog = ref(false);
    const manageGroupDialog = () => {
      dragKey.value != 1;
      isShowDialog.value = true;
    };

    const editGroupName = (item: any) => {
      item.isEdit = !item.isEdit;
    };

    return {
      groupList,
      changeGroupItem,
      selectedId,
      addGroupApi,
      manageGroupDialog,
      isShowDialog,
      closePopover,
      isShowPop,
      updateGroupApi,
      editGroupName,
      newGroupName,
      deleteGroupName,
      dragKey,
    };
  },
});
